import { isEmpty } from 'ramda';
import { createSelector } from 'reselect';
import { ICollaboratorInviteType } from 'lib/auth/types';
import { IApplicationState } from 'lib/types';

const _getAuthState = (state: IApplicationState) => state.auth;
const _getCollaboratorMethod = (state: IApplicationState) => state.auth.collaboratorInvite.method;
const _getAuthFormState = createSelector(_getAuthState, (auth) => auth.form);
const _resetPassword = createSelector(_getAuthState, (auth) => auth.resetPassword);

export const getCollaboratorInvite = createSelector(
  _getAuthState,
  (auth) => auth.collaboratorInvite,
);

export const getCollaboratorsName = createSelector(
  getCollaboratorInvite,
  (collaboratorInvite) => collaboratorInvite.partners,
);

export const getSelectedCountryCode = createSelector(
  _getAuthFormState,
  (form) => form.fields.countryCode,
);

export const getDeleteAccountInSession = createSelector(
  _getAuthState,
  (auth) => auth.deleteAccountInSession,
);

export const isCountrySelected = createSelector(
  getSelectedCountryCode,
  (countryCode: string): boolean => (countryCode ? !isEmpty(countryCode) : false),
);

export const isCollaboratorInvite = createSelector(
  [getCollaboratorInvite],
  (collaboratorInvite: ICollaboratorInviteType): boolean => Boolean(collaboratorInvite?.nonceId),
);

export const selectResetPassword = createSelector(
  [_resetPassword],
  (resetPassword) => resetPassword,
);

export const selectAuthStatus = createSelector(
  [(state: IApplicationState) => state.users.user],
  (user) => Boolean(user),
);

export const getIsSignup = createSelector(_getAuthState, (auth) => auth.signup);

export const selectCollaboratorMethod = createSelector(
  [_getCollaboratorMethod],
  (collaboratorMethod) => collaboratorMethod,
);

export const getEmailExistsWithDifferentProvider = createSelector(
  _getAuthState,
  ({ emailExistsWithDifferentProvider }) => emailExistsWithDifferentProvider,
);

export const getAuthForm = createSelector(_getAuthFormState, (authForm) => authForm);

export const getAuthFormDisabled = createSelector(_getAuthFormState, ({ disabled }) => disabled);
